import type { FC } from 'react';

import { Row } from './components/Row';
import { SubmissionDetailsBanner } from './components/SubmissionDetailsBanner';
import { SubmissionStatus } from '../../../types';

interface SubmissionDetailsProps {
  fileName: string;
  fileSize: string;
  rows?: number;
  status: SubmissionStatus;
}

const formatNumber = (value: number) => Intl.NumberFormat('en-GB').format(value);

export const SubmissionDetails: FC<SubmissionDetailsProps> = ({ fileName, fileSize, rows, status }) => {
  const isProcessed = status === SubmissionStatus.Processed;

  return (
    <div className="flex flex-col">
      <div className="border-b border-gray-200 pb-7">
        <h4 className="text-lg leading-6 font-medium text-gray-900">Submission Details</h4>
        <SubmissionDetailsBanner status={status} />
      </div>
      <Row label="Filename" value={fileName} />
      <Row label="Filesize" value={isProcessed ? fileSize : '-'} />
      <Row label="Rows" value={isProcessed ? `${formatNumber(Number(rows))} total` : '-'} />
    </div>
  );
};
