import { useState } from 'react';

import * as uuid from 'uuid';

import { getFetcher } from './use-query';
import { downloadFileFromUrl } from '../helpers';

export const useDownloadProcessedFile = (submissionId: string, fileName = uuid.v4()) => {
  const [isLoading, setIsLoading] = useState(false);

  const execute = async () => {
    try {
      setIsLoading(true);
      const data = await getFetcher(`${process.env.REACT_APP_API_URL}/submissions/${submissionId}/download/report`);
      downloadFileFromUrl(data.data.url, fileName);
      return data.data.url;
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    execute,
    isLoading,
  };
};
