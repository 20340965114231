import { CalendarIcon } from '@heroicons/react/solid';
import { useParams } from 'react-router-dom';

import { SubmissionActions } from './components/SubmissionActions';
import { SubmissionLoading } from './components/SubmissionLoading';
import { useSingleSubmission } from '../../../../data/use-single-submission';
import { SubmissionDetails } from '../../submission-details';
import { SubmissionProcessingDetails } from '../../submission-processing-details';
import { SubmissionStatusPill } from '../../submission-status';
import { UploadType } from '../../upload-type';
import { UserInfo } from '../../user-info';

export const SingleSubmission = () => {
  const { submissionId } = useParams();
  const { data, isLoading } = useSingleSubmission(submissionId as string);

  if (isLoading) {
    return <SubmissionLoading />;
  }

  const userFullName = `${data.userFirstName} ${data.userLastName}`;

  return (
    <div className="flex flex-col">
      <h1 className="text-2xl leading-8 font-bold text-gray-900 pb-2">{data.subject ?? ''}</h1>
      <div className="flex gap-x-7 border-b border-gray-200 pb-9">
        <UserInfo email={data.from} name={userFullName} />
        <UploadType type={data.via} />
        <div className="inline-flex items-center">
          <CalendarIcon className="w-4 h-4 text-gray-400 mr-2" />
          <p className="text-gray-500 text-sm leading-5 font-medium">{data.responseSent}</p>
        </div>
        <div className="ml-auto">
          <SubmissionStatusPill status={data.status} />
        </div>
      </div>
      <div className="flex pt-12 border-b border-gray-20 pb-12">
        <div className="basis-3/5">
          <SubmissionDetails fileName={data.file} fileSize={data.fileSize} rows={data.rows} status={data.status} />
        </div>
        <div className="basis-2/5 pl-20">
          <SubmissionProcessingDetails
            avgConfidenceRating={data.avgConfidenceRating}
            goldenRulePassRate={data.goldenRulePassRate}
            processingTime={data.processingTime}
            status={data.status}
          />
        </div>
      </div>
      <SubmissionActions
        isArchived={data.archived === 1}
        fileName={data.file}
        status={data.status}
        processedFileName={data.processedFile}
        submissionId={submissionId as string}
      />
    </div>
  );
};
