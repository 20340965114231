import { useSWRConfig } from 'swr';

import { useQuery } from './use-query';
import type { Submission } from '../types';
import { SubmissionStatus } from '../types';

export const useSingleSubmission = (submissionId: string) => {
  const { mutate } = useSWRConfig();
  const { data, error, isLoading } = useQuery<Submission>(`/submissions/${submissionId}`, {
    onSuccess: async () => {
      await mutate(`${process.env.REACT_APP_API_URL}/submissions?`);
    },
    refreshInterval: (latestData) => {
      const latestStatus = latestData?.data?.status;
      return latestStatus === SubmissionStatus.Uploading ? 1000 : 0;
    },
  });

  return {
    data,
    error,
    isLoading,
  };
};
