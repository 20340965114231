import { useState } from 'react';

import { Auth } from '@aws-amplify/auth';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Row } from './Rows';
import { Button, ControlledInput, Field, Form } from '../../../components';
import { appPaths } from '../../../helpers';

interface FormValues {
  email: string;
  password: string;
}

export const LoginForm = () => {
  const [error, setError] = useState<string>();
  const location = useLocation();
  const navigate = useNavigate();
  const handleSubmit = async (values: FormValues) => {
    try {
      setError(undefined);
      const user = await Auth.signIn(values.email.trim(), values.password);

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        return navigate(appPaths.setupPassword(), { state: values });
      }

      // @ts-ignore
      return navigate(location?.state?.from?.pathname ?? appPaths.home());
    } catch (err) {
      const errorMessage = (err as Error).message;
      setError(
        ['User does not exist.', 'Incorrect username or password.'].includes(errorMessage)
          ? 'Incorrect email or password.'
          : errorMessage
      );
    }
  };

  return (
    <Form initialValues={{ email: '', password: '' }} onSubmit={handleSubmit}>
      {({ submitting, touched, values }) => (
        <>
          <Row>
            <Field hasError={!!error} label="Email address" touched={touched?.email}>
              {(renderProps) => <ControlledInput {...renderProps} name="email" type="email" />}
            </Field>
          </Row>
          <Row>
            <Field errorMessage={error} hasError={!!error} label="Password" touched={touched?.password}>
              {(renderProps) => <ControlledInput {...renderProps} name="password" type="password" />}
            </Field>
          </Row>
          <Row>
            <Link to={appPaths.forgotPassword()}>
              <p className="text-blue-500 text-right font-medium text-sm">Forgot password?</p>
            </Link>
          </Row>
          <Button
            isDisabled={submitting || !values?.email?.trim() || !values?.password?.trim()}
            shouldFitContainer
            type="submit"
          >
            Sign in
          </Button>
        </>
      )}
    </Form>
  );
};
