import type { FC } from 'react';

import { Link } from 'react-router-dom';

import { NavItem } from './components/NavItem';
import { ReactComponent as Logo } from './logo.svg';
import { H3 } from '../../components';
import { appPaths, getEnvVariable } from '../../helpers';
import { useCurrentUser } from '../../hooks';
import { UserInfo } from '../user-info';

export const Navbar: FC = () => {
  const { user } = useCurrentUser();
  const consoleUrl = getEnvVariable('REACT_APP_IDENTITY_CONSOLE_URL');

  const isAdmin = user?.attributes['custom:role'] === 'admin';

  return (
    <div className="flex grow items-stretch bg-black h-20 text-white">
      <div className="flex items-center p-5">
        <Link to={appPaths.home()}>
          <Logo className="w-24 cursor-pointer" />
        </Link>
        <div className="bg-white w-0.5 h-full mx-6" />
        <H3>Digital Prop 1 - Address Validation</H3>
      </div>
      <div className="ml-auto flex text-gray-500 font-semibold">
        <NavItem label="Submissions" paths={[appPaths.home(), appPaths.singleSubmission()]} to={appPaths.home()} />
        <NavItem label="Dashboard" paths={[appPaths.dashboard()]} to={appPaths.dashboard()} />
        {isAdmin && <NavItem isExternal label="User Admin" paths={[]} href={consoleUrl} target="_blank" to={{}} />}
        <UserInfo />
      </div>
    </div>
  );
};
