import type { FC } from 'react';

import { BadgeCheckIcon, ClockIcon, ThumbUpIcon } from '@heroicons/react/solid';

import { DetailsCard } from './components/DetailsCard';
import { SubmissionStatus } from '../../../types';

interface SubmissionProcessingDetailsProps {
  avgConfidenceRating?: number;
  goldenRulePassRate?: number;
  processingTime?: number;
  status: SubmissionStatus;
}

const formatPercentageValue = (value: number | string) => `${value}%`;

export const SubmissionProcessingDetails: FC<SubmissionProcessingDetailsProps> = ({
  avgConfidenceRating,
  goldenRulePassRate,
  processingTime,
  status,
}) => {
  const isProcessed = status === SubmissionStatus.Processed;

  return (
    <div className="flex flex-col">
      <h4 className="text-lg leading-6 font-medium text-gray-900 pb-7">Processing Details</h4>
      <div className="pb-5">
        <DetailsCard
          label="Processing time"
          icon={ClockIcon}
          value={isProcessed ? `${Number(processingTime ?? 0).toFixed(2)} secs` : '-'}
        />
      </div>
      <div className="pb-5">
        <DetailsCard
          label="Avg. model prediction score"
          icon={ThumbUpIcon}
          value={isProcessed ? Number(avgConfidenceRating ?? 0).toFixed(2) : '-'}
        />
      </div>
      <DetailsCard
        label="Golden rule pass rate"
        icon={BadgeCheckIcon}
        value={isProcessed ? formatPercentageValue(Number(goldenRulePassRate ?? 0).toFixed(2)) : '-'}
      />
    </div>
  );
};
