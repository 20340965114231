import type { ReactNode } from 'react';
import React from 'react';

import clsx from 'clsx';

import logo from './logo.png';
import { H3 } from '../../../components';

interface AuthLayoutProps {
  children: ReactNode;
}

export const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => (
  <div className="h-screen flex w-full bg-center items-center relative bg-background bg-[url('/public/capita-background-logo.png')] bg-no-repeat bg-auto">
    <div className="sm:mx-auto sm:w-full sm:max-w-md z-20">
      <div
        className={clsx(
          'bg-white w-full max-w-96 xl:max-w-128 shadow-xl flex flex-col overflow-y-auto pt-8 rounded ml-auto'
        )}
      >
        <div className="flex flex-col justify-center items-center mb-2">
          <img src={logo} alt="Capita Digiprop1 - Logo" width={204} height={62} className="mb-5" />
          <H3>Digital Prop 1 - Address Validation</H3>
        </div>
        <div className="p-10 flex-auto">{children}</div>
      </div>
    </div>
  </div>
);
