import { useState } from 'react';

import format from 'date-fns/format';
import _, { isNil, omit } from 'lodash';
import { useSearchParams } from 'react-router-dom';

import { ActivityInPeriod } from './components/ActivityInPeriod';
import { DashboardLoading } from './components/DashboardLoading';
import { ProcessingGauges } from './components/ProcessingGauges';
import { DateInput, H3 } from '../../../components';
import { useDashboardStatistics } from '../../../data/use-dashboard-statistics';
import { UsersActivityTable } from '../users-activity-table';

const getInitialDate = (key: string, params: URLSearchParams) => {
  if (!key) {
    return undefined;
  }

  const value = params.get(key);
  return value ? new Date(value) : undefined;
};

export const Dashboard = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [dateFrom, setDateFrom] = useState<Date | undefined>(() => getInitialDate('dateFrom', searchParams));
  const [dateTo, setDateTo] = useState<Date | undefined>(() => getInitialDate('dateTo', searchParams));
  const { data, isLoading } = useDashboardStatistics(searchParams);

  const applyParams = (key: string, value?: Date) => {
    const dateFrom = searchParams.get('dateFrom') ?? undefined;
    const dateTo = searchParams.get('dateTo') ?? undefined;

    const parsedFilters = _({ dateFrom, dateTo }).omitBy(_.isNil).value();

    if (isNil(value)) {
      return setSearchParams(omit(parsedFilters, key as string));
    }

    return setSearchParams({ ...parsedFilters, [key]: format(value, 'yyyy-MM-dd') });
  };

  const handleDateFromChange = (value?: Date) => {
    setDateFrom(value);
    return applyParams('dateFrom', value);
  };

  const handleDateToChange = (value?: Date) => {
    setDateTo(value);
    return applyParams('dateTo', value);
  };

  return (
    <div className="flex flex-col" style={{ minHeight: 'calc(100% - 80px)' }}>
      <div className="border-b border-gray-300 p-5 flex justify-between w-full">
        <H3>Operational dashboard</H3>
        <div className="flex gap-x-1 items-center">
          <label className="text-sm leading-5 font-medium whitespace-nowrap	text-gray-700 mr-2.5 ml-5">Date From</label>
          <DateInput
            isDisabled={isLoading}
            onChange={handleDateFromChange}
            maxDate={dateTo ?? new Date()}
            value={dateFrom}
          />
          <label className="text-sm leading-5 font-medium whitespace-nowrap	text-gray-700 mr-2.5 ml-5">Date To</label>
          <DateInput
            isDisabled={isLoading}
            onChange={handleDateToChange}
            minDate={dateFrom}
            maxDate={new Date()}
            value={dateTo}
          />
        </div>
      </div>
      {isLoading ? (
        <DashboardLoading />
      ) : (
        <div className="bg-gray-50 p-5 h-full flex-1">
          <ActivityInPeriod
            avgConfidenceRating={data.stats.avgConfidenceRating}
            avgProcessingTime={data.stats.avgProcessingTime}
            rows={data.stats.rows}
            submissions={data.stats.submissions}
          />
          <ProcessingGauges
            goldenRulePassRateFailed={data.stats.goldenRulePassRateFailed}
            goldenRulePassRatePassed={data.stats.goldenRulePassRatePassed}
            submissionMethodEmail={data.stats.submissionMethodEmail}
            submissionMethodWeb={data.stats.submissionMethodWeb}
            submissionFailed={data.stats.submissionFailed}
            submissionProcessed={data.stats.submissionProcessed}
          />
          <UsersActivityTable data={data.userStats} />
        </div>
      )}
    </div>
  );
};
