import { useQuery } from './use-query';
import type { User } from '../types';

export const useUsers = () => {
  const { data, error, isLoading } = useQuery<User[]>('/users');

  return {
    data,
    error,
    isLoading,
  };
};
