import { ReactComponent as EmptyInboxIcon } from './empty-inbox-icon.svg';
import { H3, useModal } from '../../../../components';
import { NewSubmissionModal } from '../../../new-submission-modal';

export const EmptySubmission = () => {
  const { isOpen, onClose, onOpen } = useModal();

  return (
    <>
      <NewSubmissionModal isOpen={isOpen} onClose={onClose} />
      <div className="flex justify-center items-center h-full">
        <div className="flex flex-col items-center -translate-y-1/2">
          <EmptyInboxIcon />
          <H3 className="pt-7 pb-2.5 text-gray-900">Submissions Inbox</H3>
          <p className="text-base leading-6 font-normal text-gray-900 text-center">
            Please select a submission to view more details <br />
            or{' '}
            <span className="text-blue-600 cursor-pointer" onClick={onOpen}>
              create a new submission
            </span>
            .
          </p>
        </div>
      </div>
    </>
  );
};
