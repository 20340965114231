import type { FC } from 'react';

import { FilterIcon } from '@heroicons/react/solid';
import { isNil } from 'lodash';

import { FiltersLoading } from './components/FiltersLoading';
import type { ApplyFiltersFn, FiltersParsedState } from './types';
import { Button, Field, Select } from '../../../../components';
import { DateInput } from '../../../../components/date-input';
import { useUsers } from '../../../../data/use-users';
import type { User } from '../../../../types';

const formatUserName = (user?: User) => (user ? `${user.firstName} ${user.lastName}` : '');
const formatUserOption = (user: User) => ({ value: user.id, label: formatUserName(user) });
const formatStatusOption = (status?: string) => (status ? { label: status, value: status } : null);
const formatFolderOption = (option?: number) =>
  !isNil(option) ? { label: option === 0 ? 'New' : 'Archived', value: option } : null;

interface FiltersProps {
  applyFilters: ApplyFiltersFn;
  onClearFilters: () => void;
  values: FiltersParsedState;
}

export const Filters: FC<FiltersProps> = ({ applyFilters, onClearFilters, values }) => {
  const { archived, createdAtDateStart, createdAtDateEnd, status, userId } = values;
  const { data: users, isLoading } = useUsers();

  const handleDateChange = (name: 'createdAtDateStart' | 'createdAtDateEnd') => (date?: Date) => {
    return applyFilters(name, date);
  };

  const handleFolderChange = (folderType?: number) => {
    if (folderType === undefined) {
      return applyFilters('archived', undefined);
    }

    return applyFilters('archived', folderType);
  };

  const handleStatusChange = (status?: string | null) => {
    if (!status) {
      return applyFilters('status', undefined);
    }

    return applyFilters('status', status);
  };

  const handleUserIdChange = (userId?: string) => {
    if (!userId) {
      return applyFilters('userId', undefined);
    }

    return applyFilters('userId', userId);
  };

  if (isLoading) {
    return <FiltersLoading />;
  }

  const currentUserByUserId = userId && users.find((user) => user.id === userId);

  return (
    <div className="border-t border-b border-gray-200 bg-gray-100 xl:p-5 lg:p-3">
      <h4 className="text-bas leading-6 font-semibold mb-2.5">Filters</h4>
      <div className="grid gap-x-5 gap-y-4 grid-cols-2">
        <Field label="User">
          {({ id }) => (
            <Select
              inputId={id}
              onChange={(option) => handleUserIdChange(option?.value)}
              options={users.map(formatUserOption)}
              value={currentUserByUserId ? formatUserOption(currentUserByUserId) : null}
            />
          )}
        </Field>
        <Field label="Status">
          {({ id }) => (
            <Select
              inputId={id}
              onChange={(option) => handleStatusChange(option?.value)}
              options={['Processing', 'Failed', 'Processed'].map(formatStatusOption)}
              value={formatStatusOption(status)}
            />
          )}
        </Field>
        <Field label="Date from">
          {({ id }) => (
            <DateInput
              id={id}
              maxDate={createdAtDateEnd ?? new Date()}
              onChange={handleDateChange('createdAtDateStart')}
              value={createdAtDateStart}
            />
          )}
        </Field>
        <Field label="Date to">
          {({ id }) => (
            <DateInput
              id={id}
              minDate={createdAtDateStart}
              maxDate={new Date()}
              onChange={handleDateChange('createdAtDateEnd')}
              value={createdAtDateEnd}
            />
          )}
        </Field>
        <Field label="Folder">
          {({ id }) => (
            <Select
              inputId={id}
              onChange={(option) => handleFolderChange(option?.value)}
              options={[
                { value: 0, label: 'New' },
                { value: 1, label: 'Archived' },
              ]}
              value={formatFolderOption(archived)}
            />
          )}
        </Field>
        <Button
          className="self-end ml-auto"
          iconBefore={<FilterIcon />}
          onClick={onClearFilters}
          style={{ maxWidth: '165px' }}
          variant="secondary"
        >
          Clear all filters
        </Button>
      </div>
    </div>
  );
};
