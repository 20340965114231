import { useState } from 'react';

import { Auth } from '@aws-amplify/auth';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';

import { Button, ControlledInput, Field, Form, H3 } from '../../../components';
import { appPaths } from '../../../helpers';

interface FormValues {
  email: string;
}

export const ForgotPasswordForm = () => {
  const [error, setError] = useState<string>();
  const [areInstructionsSent, setInstructionsSent] = useState(false);
  const handleSubmit = async (values: FormValues) => {
    try {
      setError(undefined);
      await Auth.forgotPassword(values.email.trim());
      setInstructionsSent(true);
    } catch (err) {
      const errorMessage = (err as Error).message;
      setError(errorMessage === 'Username/client id combination not found.' ? 'Incorrect email.' : errorMessage);
    }
  };

  return (
    <div>
      {!areInstructionsSent && (
        <>
          <Link to={appPaths.login()}>
            <Button alignment="left" iconBefore={<ChevronLeftIcon />} variant="ghost">
              Return to login
            </Button>
          </Link>
          <H3 className="pt-5 pb-2.5">Forgot your password?</H3>
          <p className="text-sm leading-5 font-normal text-gray-700 pb-5">
            It happens to all of us. Just enter the email you used to create the account and we’ll send you some
            instructions to reset your password.
          </p>
          <Form initialValues={{ email: '' }} onSubmit={handleSubmit}>
            {({ submitting, touched, values }) => (
              <>
                <Field label="Email address" errorMessage={error} hasError={!!error} touched={touched?.email}>
                  {(renderProps) => <ControlledInput {...renderProps} name="email" />}
                </Field>
                <Button
                  isDisabled={submitting || !values?.email?.trim()}
                  className="mt-5"
                  shouldFitContainer
                  type="submit"
                >
                  Send reset instructions
                </Button>
              </>
            )}
          </Form>
        </>
      )}

      {areInstructionsSent && (
        <>
          <H3 className="mb-4">Instructions sent</H3>
          <p className="mb-5 text-sm leading-5 font-normal text-gray-700">
            Instructions to reset your password have been sent to you.
          </p>
          <p className="mb-6 text-sm leading-5 font-normal text-gray-700">Please check your inbox.</p>
          <div className="flex">
            <Link className="w-full" to={appPaths.login()}>
              <Button shouldFitContainer>Return to Login</Button>
            </Link>
          </div>
        </>
      )}
    </div>
  );
};
