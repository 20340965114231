import type { FC } from 'react';

import { UserCell } from './components/UserCell';
import { Table, TableCellAlignment } from '../../../components';
import { formatNumber } from '../../../helpers';
import type { UserStats } from '../../../types';

interface UsersActivityTableProps {
  data: UserStats[];
}

export const UsersActivityTable: FC<UsersActivityTableProps> = ({ data }) => {
  const tableColumns = [
    { id: 'userEmail', label: 'USER', alignment: TableCellAlignment.Left },
    { id: 'processed', label: 'PROCESSED' },
    { id: 'failed', label: 'FAILED' },
    { id: 'totalRows', label: 'TOTAL ROWS' },
    { id: 'avgProcessingTime', label: 'AVG. PROCESSING TIME' },
    { id: 'avgConfidenceRating', label: 'AVG. MODEL PREDICTION SCORE' },
    { id: 'goldenRulePassRate', label: 'GR PASS RATE' },
  ];

  const tableData = data.map((row) => [
    {
      id: 'userEmail',
      value: row.userEmail,
      render: () => (
        <UserCell
          userActive={row.userActive}
          userEmail={row.userEmail}
          userFirstName={row.userFirstName}
          userLastName={row.userLastName}
        />
      ),
    },
    { id: 'processed', value: row.processed },
    { id: 'failed', value: row.failed },
    { id: 'totalRows', value: row.totalRows, render: () => formatNumber(row.totalRows) },
    { id: 'avgProcessingTime', value: row.avgProcessingTime, render: () => `${row.avgProcessingTime} sec` },
    {
      id: 'avgConfidenceRating',
      value: row.avgConfidenceRating,
      render: () => `${Number(row.avgConfidenceRating).toFixed(2)}`,
    },
    { id: 'goldenRulePassRate', value: row.goldenRulePassRate, render: () => `${row.goldenRulePassRate}%` },
  ]);

  return (
    <div className="flex flex-col pt-8">
      <h4 className="text-lg leading-6 font-medium text-gray-900 pb-4">User Activity Breakdown</h4>
      <Table columns={tableColumns} data={tableData} />
    </div>
  );
};
