import { Auth } from '@aws-amplify/auth';
import axios from 'axios';
import type { SWRConfiguration } from 'swr';
import useSWR from 'swr';

const getAuthToken = async () => {
  const session = await Auth.currentSession();
  return session.getIdToken().getJwtToken();
};

export const getFetcher = async (url: string) => {
  const token = await getAuthToken();
  return axios.get(url, { headers: { Authorization: `Bearer ${token}` } }).then((data) => data.data);
};

export const put = async (url: string, body: unknown) => {
  const token = await getAuthToken();
  return axios({ method: 'PUT', url, headers: { Authorization: `Bearer ${token}` }, data: body }).then(
    (data) => data.data
  );
};

export const post = async (url: string, body: unknown) => {
  const token = await getAuthToken();
  return axios({ method: 'POST', url, headers: { Authorization: `Bearer ${token}` }, data: body }).then(
    (data) => data.data
  );
};

export const putFile = async (url: string, data: unknown) => {
  return axios.put(url, data);
};

export const useQuery = <Result>(path: string | null, options: SWRConfiguration = {}) => {
  const url = path ? `${process.env.REACT_APP_API_URL}${path}` : null;

  const { data, error } = useSWR(url, getFetcher, { revalidateOnFocus: false, ...options });

  return {
    data: data?.data as Result,
    error,
    isLoading: Boolean(path && !data && !error),
  };
};
