import type { FC } from 'react';

import { EmptyResults } from './components/EmptyResults';
import { SubmissionInboxItem } from './components/SubmissionInboxItem';
import { SubmissionsInboxLoading } from './components/SubmissionsInboxLoading';
import type { Submission } from '../../../../types';

interface SubmissionsInboxProps {
  data: Submission[];
  isLoading: boolean;
}

export const SubmissionsInbox: FC<SubmissionsInboxProps> = ({ data, isLoading }) => {
  if (isLoading) {
    return <SubmissionsInboxLoading />;
  }

  if (data.length === 0) {
    return <EmptyResults />;
  }

  return (
    <div className="flex flex-col overflow-auto" style={{ maxHeight: `calc(100vh - 487px)` }}>
      {data.map((item) => (
        <SubmissionInboxItem key={item.id} {...item} />
      ))}
    </div>
  );
};
